import { useModel as _useModel, mergeModels as _mergeModels } from 'vue'
import { createElementVNode as _createElementVNode, vModelDynamic as _vModelDynamic, mergeProps as _mergeProps, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["for", "innerHTML"]
const _hoisted_2 = ["id", "name"]
const _hoisted_3 = ["innerHTML"]


export default /*@__PURE__*/Object.assign({ inheritAttrs: false }, {
  __name: 'BaseInput',
  props: /*@__PURE__*/_mergeModels({
        isError: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            required: true,
        },
        lowerText: {
            type: String,
            required: false,
            default: undefined,
        },
        name: {
            type: String,
            required: true,
        },
    }, {
    "modelValue": { type: [String, Number], required: true, default: '' },
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

    
    

    const model = _useModel(__props, "modelValue");

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["base-input field", { error: __props.isError }])
  }, [
    _createElementVNode("label", {
      for: __props.name,
      innerHTML: __props.label
    }, null, 8, _hoisted_1),
    _withDirectives(_createElementVNode("input", _mergeProps({ id: __props.name }, _ctx.$attrs, {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((model).value = $event)),
      name: __props.name
    }), null, 16, _hoisted_2), [
      [_vModelDynamic, model.value]
    ]),
    (__props.lowerText)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: "form-field-lower-text",
          innerHTML: __props.lowerText
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true)
  ], 2))
}
}

})