import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"
import _imports_0 from '@public/img/icon-preferred-practice.svg'
import _imports_1 from '@public/img/icon-close-modal.svg'


const _hoisted_1 = {
  class: "modal eyecareprovider-confirm",
  role: "dialog"
}
const _hoisted_2 = { class: "inner" }
const _hoisted_3 = { class: "ecp-confirm-headline" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "preferred-ecp-card" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["alt"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "el",
    class: "modal-overlay",
    onClick: _cache[2] || (_cache[2] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h2", null, _toDisplayString($setup.t('ecp_selected.headline_copy')), 1),
          _createElementVNode("p", {
            innerHTML: $setup.t('ecp_selected.body_copy', { ...$options.prepaidCard, interpolation: { escapeValue: false } })
          }, null, 8, _hoisted_4)
        ]),
        ($props.preferredActive)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("div", _hoisted_5, [
                _cache[3] || (_cache[3] = _createElementVNode("img", {
                  src: _imports_0,
                  class: "icon"
                }, null, -1)),
                _createElementVNode("h3", null, _toDisplayString($setup.t('ecp_selected.preferred_headline', { preferredRewardMax: $options.preferredRewardMax })), 1)
              ]),
              _createElementVNode("section", {
                innerHTML: $setup.t('ecp_selected.preferred_body_copy', { preferredRewardMax: $options.preferredRewardMax, terms_link: $options.termsLink, ...$options.prepaidCard, interpolation: { escapeValue: false } })
              }, null, 8, _hoisted_6)
            ], 64))
          : _createCommentVNode("", true),
        _createElementVNode("button", {
          class: "with-right-arrow preferred-ecp-btn",
          "data-e2e": "eye-care-selected-modal-close",
          onClick: _cache[0] || (_cache[0] = _withModifiers($event => (_ctx.closeModal()), ["prevent"])),
          innerHTML: $setup.t('links:review_continue')
        }, null, 8, _hoisted_7)
      ]),
      _createElementVNode("a", {
        href: "#",
        "data-e2e": "modal-close",
        class: "close-btn",
        onClick: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, [
        _createElementVNode("img", {
          src: _imports_1,
          class: "icon-close",
          alt: $setup.t('buttons:close')
        }, null, 8, _hoisted_8)
      ])
    ])
  ], 512))
}