import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "name", "value", "accept"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["base-file-input", { error: $props.isError }])
  }, [
    _createElementVNode("label", { for: $props.name }, _toDisplayString($props.label), 9, _hoisted_1),
    _createElementVNode("input", _mergeProps({
      id: $props.name,
      ref: `input_${_ctx.$attrs._uid}`
    }, _ctx.$attrs, {
      name: $props.name,
      value: $props.modelValue,
      accept: $props.accept,
      type: "file",
      onChange: _cache[0] || (_cache[0] = (...args) => ($options.updateFile && $options.updateFile(...args)))
    }), null, 16, _hoisted_2),
    _createElementVNode("button", {
      class: "alt secondary with-upload-arrow",
      onClick: _cache[1] || (_cache[1] = _withModifiers((...args) => ($options.browseClick && $options.browseClick(...args)), ["prevent"]))
    }, _toDisplayString($props.buttonLabel), 1)
  ], 2))
}