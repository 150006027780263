import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-redirect" }
const _hoisted_2 = { class: "redirect-page-content" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_OutgoingAffiliate = _resolveComponent("OutgoingAffiliate")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", {
        innerHTML: $setup.t('headline_copy')
      }, null, 8, _hoisted_3),
      _createElementVNode("p", {
        innerHTML: $setup.t('body_copy')
      }, null, 8, _hoisted_4),
      _createVNode(_component_OutgoingAffiliate, {
        "affiliate-id": "redirect_rewards",
        target: "_parent",
        class: "btn"
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", {
            innerHTML: $setup.t('cta')
          }, null, 8, _hoisted_5)
        ]),
        _: 1
      })
    ])
  ]))
}