
    import { useI18next } from '@composables/i18next';
    import BaseModal from './BaseModal';

    export default {
        name: 'ExpressClaimNoticeModal',

        extends: BaseModal,

        props: {
            patientName: {
                type: String,
                default: '',
            },
            submissionId: {
                type: String,
                default: '',
            },
        },

        setup () {
            const { t } = useI18next(['buttons', 'modal_content']);
            return { t };
        },

        data () {
            return {
                modalName: 'express-claim-notice',
            };
        },
        methods: {
            continueToExpressRewardsPatientInfo () {
                this.closeModal();
                this.$router.push(`/express_rewards/${this.submissionId}`);
            },
        },
    };
