
// Asynchronous component definitions for lazy-loading routes.
// https://router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    ViralPage = async () => import(
        /* webpackChunkName: 'viral' */
        '../views/ViralPage.vue'
    ),
    ViralThanksPage = async () => import(
        /* webpackChunkName: 'viral' */
        '../views/ViralThanksPage.vue'
    );

export default [
    { path: '/viral', component: ViralPage },
    { path: '/viral_thanks', component: ViralThanksPage, props: { noThanks: false } },
    { path: '/viral_no_thanks', component: ViralThanksPage, props: { noThanks: true } },
];
