import { useModel as _useModel, mergeModels as _mergeModels } from 'vue'
import { vModelCheckbox as _vModelCheckbox, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "name", "disabled"]
const _hoisted_2 = ["for"]


export default /*@__PURE__*/Object.assign({ inheritAttrs: false }, {
  __name: 'BaseCheckbox',
  props: /*@__PURE__*/_mergeModels({
        isError: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    }, {
    "modelValue": { type: [String, Boolean], required: true, default: false },
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

    
    
    const model = _useModel(__props, "modelValue");

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["base-checkbox field", { error: __props.isError, disabled: __props.disabled }])
  }, [
    _withDirectives(_createElementVNode("input", _mergeProps(_ctx.$attrs, {
      id: __props.name,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((model).value = $event)),
      type: "checkbox",
      name: __props.name,
      disabled: __props.disabled
    }), null, 16, _hoisted_1), [
      [_vModelCheckbox, model.value]
    ]),
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _createElementVNode("label", { for: __props.name }, _toDisplayString(__props.label), 9, _hoisted_2)
    ])
  ], 2))
}
}

})