
    import { mapMutations } from 'vuex';
    import { useI18next } from '@composables/i18next';
    import { BaseRadioGroup } from '@components/form';
    import BaseModal from './BaseModal';

    export default {
        name: 'LanguageToggleModal',

        components: {
            BaseRadioGroup,
        },

        extends: BaseModal,

        setup () {
            const { t } = useI18next(['modal_content', 'buttons', 'links']);
            return { t };
        },

        data () {
            return {
                modalName: 'language_toggle_modal',
                changedValue: undefined,
                value: undefined,
            };
        },

        computed: {
            language: {
                get () {
                    return this.app.userLanguage;
                },
                set (value) {
                    this.changedValue = value;
                },
            },
            languageChanged () {
                return this.changedValue !== this.app.userLanguage && this.changedValue !== undefined;
            },
            options () {
                return [
                    { value: 'en', label: this.t('links:language_toggle.english') },
                    { value: 'es', label: this.t('links:language_toggle.spanish') },
                ];
            },
        },

        methods: {
            ...mapMutations({
                updateAppState: 'app/updateAppState',
            }),
            async changeLanguage () {
                await this.updateAppState({ userLanguage: this.changedValue });
                if ('URLSearchParams' in window) {
                    const searchParams = new URLSearchParams(window.location.search);
                    const locales = {
                        'en': 'en-US',
                        'es': 'es-US',
                    };
                    searchParams.set('locale', locales[this.language]);
                    window.location.search = searchParams.toString();
                }
            },
        },
    };
