import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "modal language-toggle",
  role: "dialog"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "inner" }
const _hoisted_5 = ["disabled"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BaseRadioGroup = _resolveComponent("BaseRadioGroup")

  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay general-modal larger language-toggle-modal",
    onClick: _cache[2] || (_cache[2] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", {
        class: "has-color-diamond-dark-blue",
        innerHTML: $setup.t('language_toggle.en.headline')
      }, null, 8, _hoisted_2),
      _createElementVNode("h2", {
        class: "has-color-diamond-dark-blue",
        innerHTML: $setup.t('language_toggle.es.headline')
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("form", {
          onSubmit: _cache[1] || (_cache[1] = _withModifiers((...args) => ($options.changeLanguage && $options.changeLanguage(...args)), ["prevent"]))
        }, [
          _createVNode(_component_BaseRadioGroup, {
            modelValue: $options.language,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($options.language) = $event)),
            label: "",
            name: "language",
            options: $options.options
          }, null, 8, ["modelValue", "options"]),
          _createElementVNode("button", {
            class: "with-right-arrow",
            type: "submit",
            "data-e2e": "confirm_language_button",
            disabled: !$options.languageChanged
          }, _toDisplayString($setup.t('language_toggle.button')), 9, _hoisted_5)
        ], 32)
      ])
    ])
  ]))
}