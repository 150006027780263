import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "icon-content" }
const _hoisted_2 = ["textContent"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ImageIcon = _resolveComponent("ImageIcon")

  return (_openBlock(), _createBlock(_component_ImageIcon, {
    "circle-color-class": $props.circleColorClass,
    class: "icon questions-icon"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "comments" }, null, -1)),
        _createElementVNode("h3", {
          class: "subscript",
          textContent: _toDisplayString($setup.t('buttons:questions'))
        }, null, 8, _hoisted_2)
      ])
    ]),
    _: 1
  }, 8, ["circle-color-class"]))
}