import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@public/img/acuvue-logo.png'


const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "error-message"
}
const _hoisted_4 = { role: "main" }
const _hoisted_5 = {
  id: "lensassist_proxies",
  style: {"display":"none"},
  "aria-hidden": ""
}
const _hoisted_6 = {
  ref: "rewards_online_proxy",
  observable: "showRewardsOnline",
  style: {"display":"none"}
}
const _hoisted_7 = {
  ref: "rewards_offline_proxy",
  observable: "showRewardsOffline",
  style: {"display":"none"}
}
const _hoisted_8 = {
  ref: "contact_lenses_online_proxy",
  observable: "showContactsOnline",
  style: {"display":"none"}
}
const _hoisted_9 = {
  ref: "contact_lenses_offline_proxy",
  observable: "showContactsOffline",
  style: {"display":"none"}
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view")
  const _component_QuestionsIcon = _resolveComponent("QuestionsIcon")
  const _component_TheFooter = _resolveComponent("TheFooter")
  const _component_CookieGate = _resolveComponent("CookieGate")

  return (_openBlock(), _createElementBlock("div", {
    id: "main",
    class: _normalizeClass([{ 'diamond-plus': $options.isDiamondPlus }, $options.pageClass])
  }, [
    _createElementVNode("div", _hoisted_1, [
      ($options.showHeaderLogo)
        ? (_openBlock(), _createElementBlock("header", _hoisted_2, _cache[0] || (_cache[0] = [
            _createElementVNode("img", {
              src: _imports_0,
              class: ""
            }, null, -1)
          ])))
        : _createCommentVNode("", true),
      (_ctx.isAdminImpersonation)
        ? (_openBlock(), _createElementBlock("h2", _hoisted_3, " You are impersonating " + _toDisplayString(_ctx.profile.first_name), 1))
        : _createCommentVNode("", true),
      _createElementVNode("main", _hoisted_4, [
        _createVNode(_component_router_view, { class: "content" }),
        ($options.showQuestionsIcon)
          ? (_openBlock(), _createBlock(_component_QuestionsIcon, {
              key: 0,
              class: "questions-icon",
              "circle-color-class": "lightBlue",
              onClick: $options.openQuestionsModal
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("span", _hoisted_6, null, 512),
        _createElementVNode("span", _hoisted_7, null, 512),
        _createElementVNode("span", _hoisted_8, null, 512),
        _createElementVNode("span", _hoisted_9, null, 512)
      ]),
      _createVNode(_component_TheFooter),
      _createVNode(_component_CookieGate, {
        "body-copy": $setup.t('cookie_gate:body_copy'),
        "button-copy": $setup.t('cookie_gate:button_copy'),
        "title-copy": $setup.t('cookie_gate:title')
      }, null, 8, ["body-copy", "button-copy", "title-copy"])
    ])
  ], 2))
}