import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "promocode-subtotal subtotal-container promocode-applied-confirmation" }
const _hoisted_3 = { class: "subtotal-description" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "subtotal-amount has-color-light-blue" }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  id: "promocode_form",
  name: "promocode_form",
  novalidate: "",
  class: "product-form promocode-form"
}
const _hoisted_9 = { class: "form-container" }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { class: "promocode-form-container" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = { class: "form-field-container" }
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = { class: "promocode-button-container" }
const _hoisted_16 = ["disabled"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BaseInput = _resolveComponent("BaseInput")
  const _component_Validator = _resolveComponent("Validator")

  return ($data.promocodeApplied || $options.codePreviouslyUsed)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", {
              class: "promocode-headline",
              innerHTML: $setup.t('promocode_applied.headline_copy')
            }, null, 8, _hoisted_4),
            _createElementVNode("span", {
              class: "promocode-bodycopy",
              innerHTML: $setup.t('promocode_applied.body_copy', { promocodeBonusAmount: $data.promocodeBonusAmount })
            }, null, 8, _hoisted_5)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _cache[4] || (_cache[4] = _createElementVNode("span", { class: "dolly-ollar" }, "$", -1)),
            _createTextVNode(_toDisplayString($data.promocodeBonusAmount), 1),
            _cache[5] || (_cache[5] = _createElementVNode("sup", null, " ", -1))
          ])
        ]),
        _createElementVNode("button", {
          class: "promocode-deletecopy",
          onClick: _cache[0] || (_cache[0] = _withModifiers($event => ($options.deletePromocode()), ["prevent"]))
        }, _toDisplayString($setup.t('promocode_form:promocode_applied.delete_copy')), 1)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_7, [
        _createVNode(_component_Validator, {
          ref: "promocodeValidatorEl",
          guards: $data.promocodeValidator,
          data: $data.promocodeFormData
        }, {
          default: _withCtx(({
                hasErrors, isError,
            }) => [
            _createElementVNode("form", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _withDirectives(_createElementVNode("p", {
                  class: "promocode-default-headline",
                  innerHTML: $setup.t('default_form.headline')
                }, null, 8, _hoisted_10), [
                  [_vShow, !hasErrors]
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _withDirectives(_createElementVNode("p", {
                    class: "promocode-error",
                    innerHTML: $setup.t('errors.headline_copy')
                  }, null, 8, _hoisted_12), [
                    [_vShow, hasErrors]
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _createVNode(_component_BaseInput, {
                      modelValue: $data.promocodeFormData.code,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.promocodeFormData.code) = $event)),
                      label: $setup.t('form_fields.code'),
                      name: "code",
                      disabled: $options.formInputDisabled('code'),
                      "is-error": isError('code')
                    }, null, 8, ["modelValue", "label", "disabled", "is-error"]),
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($data.promocodeFormData.purchase_submission_id) = $event)),
                      type: "hidden",
                      name: "purchase_submission_id"
                    }, null, 512), [
                      [_vModelText, $data.promocodeFormData.purchase_submission_id]
                    ])
                  ]),
                  _withDirectives(_createElementVNode("p", {
                    class: "promocode-error",
                    innerHTML: $setup.t($data.hasCodeAlreadyUsedError ? 'errors.code_already_used' : 'errors.code_invalid')
                  }, null, 8, _hoisted_14), [
                    [_vShow, hasErrors]
                  ]),
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("button", {
                      type: "button",
                      class: "with-right-arrow",
                      disabled: !$data.promocodeFormData.code || $data.isSubmittingPromocode,
                      onClick: _cache[3] || (_cache[3] = _withModifiers($event => ($options.applyPromocode()), ["prevent"]))
                    }, _toDisplayString($setup.t('apply')), 9, _hoisted_16)
                  ])
                ])
              ])
            ])
          ]),
          _: 1
        }, 8, ["guards", "data"])
      ]))
}