import { useModel as _useModel, mergeModels as _mergeModels } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["for", "innerHTML"]
const _hoisted_2 = ["id", "name"]
const _hoisted_3 = {
  value: "",
  disabled: "",
  selected: ""
}
const _hoisted_4 = ["value"]
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { selected: "" }


export default /*@__PURE__*/Object.assign({ inheritAttrs: false }, {
  __name: 'BaseSelect',
  props: /*@__PURE__*/_mergeModels({
        disabled: {
            type: Boolean,
            default: false,
        },
        isError: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
        },
        name: {
            type: String,
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
        selectOne: {
            type: String,
            default: 'Select One',
        },
    }, {
    "modelValue": { type: [String, Number], required: true, default: '' },
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

    
    
    const model = _useModel(__props, "modelValue");

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["base-select field", { error: __props.isError, disabled: __props.disabled }])
  }, [
    (__props.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: __props.name,
          innerHTML: __props.label
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    (__props.options.length > 0)
      ? _withDirectives((_openBlock(), _createElementBlock("select", {
          key: 1,
          id: __props.name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((model).value = $event)),
          name: __props.name
        }, [
          _createElementVNode("option", _hoisted_3, _toDisplayString(__props.selectOne), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.options, (option, index) => {
            return (_openBlock(), _createElementBlock("option", {
              key: `option_${index}`,
              value: option.value
            }, _toDisplayString(option.label), 9, _hoisted_4))
          }), 128))
        ], 8, _hoisted_2)), [
          [_vModelSelect, model.value]
        ])
      : (_openBlock(), _createElementBlock("select", _hoisted_5, [
          _createElementVNode("option", _hoisted_6, _toDisplayString(__props.selectOne), 1)
        ]))
  ], 2))
}
}

})