import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BaseTooltip = _resolveComponent("BaseTooltip")

  return (_openBlock(), _createBlock(_component_BaseTooltip, { class: "tooltip-unaffiliated" }, {
    tooltip: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("i", { class: "fa-sharp fa-solid fa-circle-question" }, null, -1)
    ])),
    content: _withCtx(() => [
      _createElementVNode("h4", {
        class: "tooltip-headline",
        innerHTML: $setup.t('unaffiliated_ecp_tooltip.headline_copy')
      }, null, 8, _hoisted_1),
      _createElementVNode("section", {
        class: "tooltip-body",
        innerHTML: $setup.t('unaffiliated_ecp_tooltip.unaffiliated_ecp_flag.body_copy')
      }, null, 8, _hoisted_2)
    ]),
    _: 1
  }))
}