
import { onBeforeMount, onBeforeUnmount, onMounted, onUnmounted, toValue, computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useI18next } from '@composables/i18next';
import termsSubPhase from '../utilities/terms-sub-phase';

import { page } from '../plugins/WebAnalytics';

export function useBasePage (pageName, pageClasses = []) {
    const route = useRoute();
    const store = useStore();

    const { t } = useI18next(['global']);

    const app = computed(() => store.state.app);
    const profile = computed(() => store.state.profile);

    const isDiamondPlus = ref(false);

    // computed
    const prepaidCard = computed(() => {
        let copy = t('global:prepaid_card');
        // This is for the front end tests. Will need to be refactored;
        copy = (typeof copy === 'string') ? { default: {} } : copy;
        const { card_format = {} } = (copy[app.value.subPhase]) || (copy.default);
        const { first_card_mention = '', second_card_mention = '', following_card_mentions = '' } = card_format;
        return { first_card_mention, second_card_mention, following_card_mentions };
    });

    const checkDiamondPlus = computed(() => {
        const milestones = termsSubPhase.getCurrentMilestones(app.value.now);
        return milestones.includes('diamond_plus');
    });

    onBeforeMount(() => {
        store.commit('ui/pageEnter', {
            pageName: toValue(pageName),
            pageClasses: toValue(pageClasses),
        });

        const { query: { affiliate_id } } = route;

        // If there was an affiliate in the "query string" embedded in the Vue
        // Router hash portion of the URL, pass it along to the app store.
        if (affiliate_id) {
            store.commit('app/hashAffiliateUpdate', affiliate_id);
        }

        page();
    });

    onMounted(() => {
        document.body.setAttribute('data-e2e-page', toValue(pageName));
        document.body.setAttribute('id', toValue(pageName));
        document.body.classList.add(toValue(pageName));
        if (checkDiamondPlus.value) {
            isDiamondPlus.value = true;
            document.documentElement.classList.add('diamond-plus');
            document.body.classList.add('diamond-plus');
        }
    });

    onBeforeUnmount(() => {
        store.commit('ui/pageLeave');
    });

    onUnmounted(() => {
        document.body.removeAttribute('data-e2e-page');
        document.body.removeAttribute('id');
        document.body.removeAttribute('class');
    });

    // methods
    const find = (selector) => {
        if (!this.$el) return;
        return this.$el.querySelector(selector);
    };

    const scrollToTop = () => {
        try {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
            window.pym.scrollParentTo('rewardsIframe');
        }
        catch (error) {
            console.warn('Unable to adjust scroll position.');
        }
    };

    return {
        pageName,
        pageClasses,
        find,
        scrollToTop,
        prepaidCard,
        checkDiamondPlus,
        app,
        profile,
    };
}
