import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tooltip-container" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      ref: "tooltip",
      onMouseover: _cache[0] || (_cache[0] = (...args) => ($options.showTooltip && $options.showTooltip(...args))),
      onMouseout: _cache[1] || (_cache[1] = (...args) => ($options.hideTooltip && $options.hideTooltip(...args))),
      onClick: _cache[2] || (_cache[2] = (...args) => ($options.showTooltip && $options.showTooltip(...args)))
    }, [
      _renderSlot(_ctx.$slots, "tooltip", {}, () => [
        _cache[5] || (_cache[5] = _createElementVNode("i", { class: "fal fa-question-circle" }, null, -1))
      ], true)
    ], 544),
    _createElementVNode("div", {
      ref: "tooltipContainer",
      class: _normalizeClass(["tooltip-content", { show: $data.show, 'topped': $data.aboveTop < 0 }]),
      style: _normalizeStyle($options.cssStyle),
      onMouseover: _cache[3] || (_cache[3] = (...args) => ($options.clearTimeout && $options.clearTimeout(...args))),
      onMouseout: _cache[4] || (_cache[4] = (...args) => ($options.hideTooltip && $options.hideTooltip(...args)))
    }, [
      _renderSlot(_ctx.$slots, "content", {}, () => [
        _cache[6] || (_cache[6] = _createTextVNode(" hey there "))
      ], true),
      _cache[7] || (_cache[7] = _createElementVNode("div", {
        class: "arrow",
        "data-popper-arrow": ""
      }, null, -1))
    ], 38)
  ]))
}